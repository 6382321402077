import React, { useState, useEffect } from 'react'
import { useStore } from 'effector-react'
import { Link } from '@reach/router'

import DirectoryLayout from './Directory/DirectoryLayout'
import Header from './Directory/Header'
import Stories from './Directory/Stories'
import Filters from './Directory/Filters'
import List from './Directory/List'
import Portal from './shared/Portal'
import MainMenu from './Directory/MainMenu'
import FixedFooter from './shared/FixedFooter'
import FlowButton from './shared/FlowButton'

import { $restaurantCategories, $takeawayMode } from '../store'
import { loadRestaurantCategories } from '../store/restaurantCategories'
import { loadOrdersEffect } from '../store/orders'
import {
  exitRestaurantEvent,
  $enreachedRestaurants,
} from '../store/restaurants'
import { $cartTotalPrice, $cartShop } from '../store/cart'

function Directory() {
  const [isMenuShown, setIsMenuShown] = useState(false)
  const [category, setCategory] = useState('')

  const restaurantCategories = useStore($restaurantCategories)
  const restaurants = useStore($enreachedRestaurants)
  const cartShop = useStore($cartShop)
  const cartTotalPrice = useStore($cartTotalPrice)
  const takeawayMode = useStore($takeawayMode)

  useEffect(() => {
    loadRestaurantCategories()
    exitRestaurantEvent()
    loadOrdersEffect()

    window.scrollTo(0, 0)

    //let isSubscribed = true
    //loadRestaurantCategories({ isSubscribed })
    //loadOrdersEffect({ isSubscribed })
    //return () => {
    //isSubscribed = false
    //exitRestaurantEvent()
    //}
  }, [])

  const filteredRestaurants = restaurants
    .filter(({ confirmed }) => confirmed)
    .filter(({ dishcategories }) =>
      dishcategories.some(({ translit }) =>
        category ? translit === category : true,
      ),
    )
    .filter(({ takeaway }) => (takeawayMode ? takeaway : true))

  return (
    <DirectoryLayout>
      <Header onMenuClick={() => setIsMenuShown(true)} />
      <Stories />
      <Filters
        restaurantCategories={restaurantCategories}
        category={category}
        onChange={setCategory}
      />
      <List
        restaurants={filteredRestaurants}
        isFiltered={takeawayMode || category}
      />
      <FixedFooter isShown={cartShop}>
        {cartShop && (
          <FlowButton
            as={Link}
            to={`/restaurant/${cartShop.id}`}
            text={`${cartShop.title}`}
            right={`${cartTotalPrice} ₽`}
          />
        )}
      </FixedFooter>
      <Portal>
        <MainMenu isShown={isMenuShown} showingSetter={setIsMenuShown} />
      </Portal>
    </DirectoryLayout>
  )
}
export default Directory
